const TESTING_SERVER = false

const URLS = {
  SERVER: TESTING_SERVER
    ? `http://192.168.0.23:8080`
    : `https://dixieshowtimes.com`,
}

const SIZE = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tabletS: "480px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
}

const DEVICES = {
  maxMobileS: `(max-width: ${SIZE.mobileS})`,
  maxMobileM: `(max-width: ${SIZE.mobileM})`,
  maxMobileL: `(max-width: ${SIZE.mobileL})`,
  maxTabletS: `(max-width: ${SIZE.tabletS})`,
  maxTablet: `(max-width: ${SIZE.tablet})`,
  maxLaptop: `(max-width: ${SIZE.laptop})`,
  maxLaptopL: `(max-width: ${SIZE.laptopL})`,
  maxDesktop: `(max-width: ${SIZE.desktop})`,
  maxDesktopL: `(max-width: ${SIZE.desktop})`,
  minMobileS: `(min-width: ${SIZE.mobileS})`,
  minMobileM: `(min-width: ${SIZE.mobileM})`,
  minMobileL: `(min-width: ${SIZE.mobileL})`,
  minTabletS: `(min-width: ${SIZE.tabletS})`,
  minTablet: `(min-width: ${SIZE.tablet})`,
  minLaptop: `(min-width: ${SIZE.laptop})`,
  minLaptopL: `(min-width: ${SIZE.laptopL})`,
  minDesktop: `(min-width: ${SIZE.desktop})`,
  minDesktopL: `(min-width: ${SIZE.desktop})`,
}

const CARRIERS = [
  `ATT`,
  `TMobile`,
  `Verizon`,
  `Sprint`,
  `XFinity`,
  `Virgin`,
  `Metro`,
  `Boost`,
  `Cricket`,
  `Republic`,
  `GoogleFi`,
  `USCellular`,
  `Ting`,
  `ConsumerCellular`,
  `CSpire`,
  `PagePlus`,
]

const SEEDS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]
const ROUNDS = [
  { name: "FIRST ROUND", value: 1 },
  { name: "SECOND ROUND", value: 2 },
  { name: "ROUND OF SIXTEEN", value: 3 },
  { name: "ROUND OF EIGHT", value: 4 },
  { name: "ROUND OF FOUR", value: 5 },
  { name: "CHAMPIONSHIP", value: 6 },
]

export { URLS, DEVICES, SIZE, CARRIERS, SEEDS, ROUNDS }
