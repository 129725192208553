const Theme = {
  Red: `#ff0000`,
  Green: `#90ee90`,
  Black: `#000000`,
  White: `#ffffff`,
  FontPrimary: `Arial, Helvetica, sans-serif;`,
  BracketStroke: `#DBDCDE`,
  BracketStrokeWidth: `2px`,

  primary: `#ff0000`, // red
  background: "#FFFFFF", // white
  offBackground: "#212E40", // light grey
  surface: "#FFFFFF", // white
  error: "#B00020", // red
  onPrimary: "#FFFFFF", // white
  onSecondary: "#000000", // black
  onBackground: "#000000", // black
  onSurface: "#000000", // black
  onError: "#FFFFFF", // white
  input: "#b2b6b8", // light grey
  fontPrimary: `"Roboto-Regular", sans-serif, Helvetica Neue, Helvetica, Arial,
  sans-serif !important;`,
  fontSecondary: `"SourceCodePro-Regular", sans-serif, Helvetica Neue, Helvetica,
  Arial, sans-serif !important;`,
}

export default Theme
