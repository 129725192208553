import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"
import { ToastProvider } from "../Toasts"

// Master CSS Import
import "../../styles/styles.css"
// Styled Components Theme/Global
import { GlobalStyles, Theme } from "../../styles"

const Layout = ({ children }) => (
  <ThemeProvider theme={Theme}>
    <GlobalStyles />
    <ToastProvider>
      <main>{children}</main>
    </ToastProvider>
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
