import { navigate } from "gatsby"
import axios from "axios"
import jwtDecode from "jwt-decode"
import { URLS } from "../../config"

import {
  LOADING,
  LOADING_CLEAR,
  AUTH_USER,
  UNAUTH_USER,
  AUTH_ERROR,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  REGISTER_ERROR,
  UPDATE_BRACKET_STATE,
  BRACKET_SUCCESS,
  BRACKET_ERROR,
  CSV_SUCCESS,
  CSV_ERROR,
  UPDATE_BRACKET_ERROR,
  UPDATE_BRACKET_SUCCESS,
} from "../types"
/** ------------------------------------------------------------------
 *? Register
 */
export const Register = (name, email, password, phone, birthday) => {
  return async dispatch => {
    dispatch({ type: LOADING })
    try {
      const { data } = await axios.post(`${URLS.SERVER}/register`, {
        name,
        email,
        password,
        phone,
        birthday,
      })
      const { token, bracket } = data
      const user = jwtDecode(token)
      setToken(token)
      dispatch({ type: AUTH_USER, payload: { data, user, bracket } })
      navigate("/dashboard")
    } catch (error) {
      if (error) {
        dispatch({
          type: REGISTER_ERROR,
          payload: error.response.data.message,
        })
      } else {
        dispatch({
          type: AUTH_ERROR,
          payload: `Unknown error registering`,
        })
      }
    }
  }
}
/** ------------------------------------------------------------------
 *? Submit Bracket
 */
export const CreateBracket = bracket => {
  return async dispatch => {
    dispatch({ type: LOADING })
    const token = getToken()
    try {
      const response = await fetch(`${URLS.SERVER}/createbracket`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
        body: JSON.stringify(bracket),
      })
      const resStatus = response.status
      const resBody = await response.json()
      switch (resStatus) {
        case 200:
          dispatch({ type: BRACKET_SUCCESS, payload: resBody })
          break
        case 400:
          dispatch({ type: BRACKET_ERROR, payload: resBody.message })
          break
        default:
          dispatch({ type: BRACKET_ERROR, payload: resBody.message })
          break
      }
    } catch (error) {
      if (error) {
        dispatch({
          type: BRACKET_ERROR,
          payload: error.response.data.message,
        })
      } else {
        dispatch({
          type: AUTH_ERROR,
          payload: `Unknown error updating bracket`,
        })
      }
    }
  }
}
/** ------------------------------------------------------------------
 *? Update Bracket State in Redux
 */
export const UpdateBracketState = bracket => {
  return async dispatch => {
    dispatch({ type: UPDATE_BRACKET_STATE, payload: bracket })
  }
}
/** ------------------------------------------------------------------
 *? Get All Users (.csv)
 */
export const GetUsersCsv = () => {
  return async dispatch => {
    const token = getToken()
    try {
      const response = await fetch(`${URLS.SERVER}/exportuserdata`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      })
      const { status } = response
      if (status === 200) {
        const blob = await response.blob()
        const url = window.URL.createObjectURL(new Blob([blob]))
        let a = document.createElement("a")
        a.href = url
        a.download = "users.csv"
        a.click()
        dispatch({ type: CSV_SUCCESS, payload: "Downloading!" })
      } else {
        dispatch({ type: CSV_ERROR, payload: "Something Bad Happened" })
      }
    } catch (error) {
      if (error) {
        dispatch({
          type: CSV_ERROR,
          payload: error.response.data.message,
        })
      } else {
        dispatch({
          type: CSV_ERROR,
          payload: `Unknown error getting .csv`,
        })
      }
    }
  }
}
/** ------------------------------------------------------------------
 *? Update Admin Bracket
 */
export const UpdateAdminBracket = bracket => {
  return async dispatch => {
    dispatch({ type: LOADING })
    const token = getToken()
    try {
      const response = await fetch(`${URLS.SERVER}/updatebracket`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
        body: JSON.stringify(bracket),
      })
      const resStatus = response.status
      const resBody = await response.json()
      switch (resStatus) {
        case 200:
          dispatch({ type: UPDATE_BRACKET_SUCCESS, payload: resBody.message })
          break
        case 400:
          dispatch({ type: UPDATE_BRACKET_ERROR, payload: resBody.message })
          break
        default:
          dispatch({ type: UPDATE_BRACKET_ERROR, payload: resBody.message })
          break
      }
    } catch (error) {
      if (error) {
        console.log("error: ", error)
        dispatch({
          type: UPDATE_BRACKET_ERROR,
          payload: error.response.data.message,
        })
      } else {
        dispatch({
          type: UPDATE_BRACKET_ERROR,
          payload: `Unknown error updating bracket`,
        })
      }
    }
  }
}
/** ------------------------------------------------------------------
 *? Login
 */
export const Login = (email, password) => {
  return async dispatch => {
    dispatch({ type: LOADING })
    try {
      const { data } = await axios.post(`${URLS.SERVER}/login`, {
        email,
        password,
      })
      const { token, bracket } = data
      const user = jwtDecode(token)
      setToken(token)
      dispatch({
        type: AUTH_USER,
        payload: { data, user, bracket },
      })
      navigate("/inbetween")
    } catch (error) {
      if (error) {
        dispatch({
          type: AUTH_ERROR,
          payload: error.response.data.message,
        })
      } else {
        dispatch({
          type: AUTH_ERROR,
          payload: `Unknown error with login`,
        })
      }
    }
  }
}
/** ------------------------------------------------------------------
 *? Forgot Password
 */
export const ForgotPassword = email => {
  return async dispatch => {
    dispatch({ type: LOADING })
    try {
      const { data } = await axios.post(`${URLS.SERVER}/forgotpassword`, {
        email,
      })
      const { message } = data
      dispatch({ type: FORGOT_PASSWORD, payload: message })
    } catch (error) {
      if (error) {
        dispatch({
          type: AUTH_ERROR,
          payload: error.response.data.message,
        })
      } else {
        dispatch({
          type: AUTH_ERROR,
          payload: `Unknown error recovering password`,
        })
      }
    }
  }
}
/** ------------------------------------------------------------------
 *? Reset Password
 */
export const Reset = (password, token) => {
  return async dispatch => {
    dispatch({ type: LOADING })
    try {
      const { data } = await axios.post(`${URLS.SERVER}/resetpassword`, {
        password,
        token,
      })
      const { message } = data
      dispatch({ type: RESET_PASSWORD, payload: message })
      navigate("/login")
    } catch (error) {
      if (error) {
        dispatch({
          type: AUTH_ERROR,
          payload: error.response.data.message,
        })
      } else {
        dispatch({
          type: AUTH_ERROR,
          payload: `Unknown error reset password`,
        })
      }
    }
  }
}
/** ------------------------------------------------------------------
 *? Logout
 */
export const Logout = () => {
  return async dispatch => {
    clearPersist()
    clearToken()
    dispatch({ type: UNAUTH_USER })
    navigate("/")
  }
}
/** ------------------------------------------------------------------
 *? Loading Clear
 */
export const LoadingClear = () => {
  return async dispatch => {
    dispatch({ type: LOADING_CLEAR })
  }
}
/** ------------------------------------------------------------------
 * [Local_Storage_Helpers]
 */
const getToken = () => {
  return localStorage.getItem("ID_TOKEN_KEY")
}
const clearPersist = () => {
  localStorage.removeItem("persist:root")
}
const clearToken = () => {
  localStorage.removeItem("ID_TOKEN_KEY")
}
const setToken = token => {
  localStorage.setItem("ID_TOKEN_KEY", token)
}
