import Styled from "styled-components"

const Form = Styled.form`
	display: flex;
	flex-direction: column;
	justify-content: center;
	label, input, textarea, select {
		width: 100%;
        text-indent: 5px;
    	box-sizing: border-box;
		display: flex;
		justify-content: flex-start;
		color: ${({ theme }) => theme.onSurface};
	}
    label {
        justify-content: center;
        margin-top: 10px;
    }
    input, textarea, select {
		padding: 10px 15px;
		border: 0;
		margin-bottom: 3px;
        justify-content: flex-start;
		font-family: ${({ theme }) => theme.fontSecondary};
        border-color: rgb(166, 166, 166);
        border-width: 1px;
        border-style: solid;
		&:-webkit-autofill {
    		-webkit-box-shadow: ${({ theme }) => `0 0 0 100px ${theme.input} inset`};
			-webkit-text-fill-color: ${({ theme }) => theme.onSurface};
        }
        &::placeholder {
            color: ${({ theme }) => theme.onSurface};
        }
    }
	input:focus, textarea:focus, select:focus {
		outline: ${({ theme }) =>
      theme ? `${theme.primary} auto 5px` : "#000000 auto 5px"}
	}
	textarea {
		resize: none;
		min-height: 150px;
    	margin-bottom: 5px;
	}
	button {
		margin-top: 10px;
	}
    select {
        background-color: transparent;
        height: 50px;
        border-radius: 0;
        /* for Firefox */
        -moz-appearance: none;
        /* for Chrome */
        -webkit-appearance: none;
        /* For IE10 */
        &::-ms-expand {
            display: none;
        }
`
const EyeHolder = Styled.span`
    svg {
        cursor: pointer;
        float: right;
        right: 5px;
        margin-top: -35px;
        position: relative;
        z-index: 10;
    }
`
export { Form, EyeHolder }
